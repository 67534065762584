<template>
	<div class="ipo-detail-page">

		<section class="layer">
			<div class="layer-content">
				<van-row class="table-item">
					<van-col span="24"><span class="ipo-code">{{code}}</span><span class="ipo-name">{{name}}</span></van-col>
				</van-row>
				<van-row class="table-item">
					<van-col span="24"><span class="ipo-status">{{status}}</span></van-col>
				</van-row>
			</div>
		</section>
		
		<van-steps :active="step_active" active-color="#FE740D">
			<van-step><p>开始认购</p><p style="position:relative;top:25px">{{start_buy_day}}</p></van-step>
			<van-step><p>截止认购</p><p style="position:relative;top:25px;text-align:center;">{{end_buy_day}}</p></van-step>
			<van-step><p>公布中签</p><p style="position:relative;top:25px;text-align:center;">{{public_day}}</p></van-step>
			<van-step><p>暗盘交易</p><p style="position:relative;top:25px;text-align:center;">{{grey_start_date}}</p></van-step>
			<van-step><p style="text-align:right;">上市</p><p style="position:relative;top:25px;text-align:right;">{{open_day}}</p></van-step>
		</van-steps>
		
		<van-tabs v-model="tab_active" title-active-color="#EE0A24" class="tab-main">
			<van-tab title="发行资料">
				<div class="tab-content">

					<section class="layer">
						<h4 class="layer-title">基本信息</h4>
						<div class="layer-content">
							<van-row v-for="(item, key) in pd_info" :key="key" class="table-item">
								<van-col span="12" class="name">{{item.name}}</van-col>
								<van-col span="12" class="text-right">{{item.value}}</van-col>
							</van-row>
						</div>
					</section>

					<section class="layer investor-box">
						<h4 class="layer-title">基石投资者</h4>
						<div class="layer-content">
							<van-row class="table-title">
								<van-col span="8" class="name">名称</van-col>
								<van-col span="8" class="text-right">
									<p>计划认购数(股)</p>
									<p>占比</p>
								</van-col>
								<van-col span="8" class="text-right">
									<p>实际认购数(股)</p>
									<p>占比</p>
								</van-col>
							</van-row>
							<van-row v-for="(item, key) in pd_investor" :key="key" class="table-item">
								<van-col span="8">{{item.name}}</van-col>
								<van-col span="8" class="text-right">
									<p>{{item.plan_qty}}</p>
									<p>{{item.plan_proportion}}%</p>
								</van-col>
								<van-col span="8" class="text-right">
									<p>{{item.actual_qty}}</p>
									<p>{{item.actual_proportion}}%</p>
								</van-col>
							</van-row>
						</div>
					</section>
	
					<section class="layer">
						<h4 class="layer-title">保荐人</h4>
						<div class="layer-content">
							<van-row class="table-title">
								<van-col span="18">名称</van-col>
								<van-col span="6" class="text-right">类别</van-col>
							</van-row>
							<van-row v-for="(item, key) in pd_sponsor" :key="key" class="table-item">
								<van-col span="18">{{item.name}}</van-col>
								<van-col span="6" class="text-right">{{item.type}}</van-col>
							</van-row>
						</div>
					</section>

					<section class="layer">
						<h4 class="layer-title">承销商</h4>
						<div class="layer-content">
							<van-row v-for="(item, key) in pd_underwriter" :key="key" class="table-item">
								<van-col span="24">{{item.name}}</van-col>
							</van-row>
						</div>
					</section>


				</div>
			</van-tab>
			<van-tab title="简况">
				<div class="tab-content">
					<section class="layer">
						<h4 class="layer-title">公司概况</h4>
						<div class="layer-content">
							<van-row v-for="(item, key) in co_info" :key="key" class="table-item">
								<van-col span="6" class="name">{{item.name}}</van-col>
								<van-col span="18">{{item.value}}</van-col>
							</van-row>
						</div>
					</section>

					<section class="layer">
						<h4 class="layer-title">公司简介</h4>
						<div class="layer-content">
							<div class="desc" v-html="co_description"></div>
						</div>
					</section>

					<section class="layer">
						<h4 class="layer-title">公司高管</h4>
						<div class="layer-content">
							<van-row class="table-title">
								<van-col span="6">姓名</van-col>
								<van-col span="18">职务</van-col>
							</van-row>
							<van-row v-for="(item, key) in co_manager" :key="key" class="table-item">
								<van-col span="6" class="name">{{item.name}}</van-col>
								<van-col span="18">{{item.duty}}</van-col>
							</van-row>
						</div>
					</section>

				</div>
			</van-tab>
		</van-tabs>
	</div>
</template>

<script>
	export default {
		name: 'ipo_detail',
		data() {
			return {

				id: 0,

				step_active: 0,
				tab_active: 0,

				name: "",
				code: "",
				status: "",

				pd_info: [],
				pd_investor: [],
				pd_sponsor: [],
				pd_underwriter: [],
				co_info: [],
				co_manager: [],
				co_description: "",

				start_buy_day: "",
				end_buy_day: "",
				public_day: "",
				grey_start_date: "",
				open_day: "",

			}
		},
		mounted() {
			this.id = this.$route.query.id
		
			this.loadData()
		},
		methods: {
			loadData() {
				this.$axios({
					method: 'post',
					url: 'ipo_ipo/detail', 
					data: {
						id: this.id
					}
				}).then ((res) => {
					
					console.log(res)
					
					if (res.success) {
						
						this.step_active = res.data.time_step_index
						this.pd_info = JSON.parse(res.data.pd_info)
						this.pd_investor = JSON.parse(res.data.pd_investor)
						this.pd_sponsor = JSON.parse(res.data.pd_sponsor)
						this.pd_underwriter = JSON.parse(res.data.pd_underwriter)
						this.co_info = JSON.parse(res.data.co_info)
						this.co_manager = JSON.parse(res.data.co_manager)
						this.co_description = res.data.co_description

						this.name = res.data.name
						this.code = res.data.code
						this.status = res.data.status
						this.start_buy_day = res.data.start_buy_day
						this.end_buy_day = res.data.end_buy_day
						this.public_day = res.data.public_day
						this.grey_start_date = res.data.grey_start_date
						this.open_day = res.data.open_day		
										
						
					}
				})
			},
			onConfirm(value) {
				this.formItem.method = value
				this.showPicker = false
			},
			backClick() {
				console.log('back')
			}
		},
	}
</script>